import { render, staticRenderFns } from "./PagesNav.vue?vue&type=template&id=710dac97&scoped=true"
import script from "./PagesNav.vue?vue&type=script&lang=js"
export * from "./PagesNav.vue?vue&type=script&lang=js"
import style0 from "./PagesNav.vue?vue&type=style&index=0&id=710dac97&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_css-loader@6.11.0_webpack@5.97.1__lodash@4.17.21__vkvac2xlh3aumv4skncaxgovzu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "710dac97",
  null
  
)

export default component.exports