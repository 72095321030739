import Vue from 'vue'
import { Dialog, Button, Checkbox, Message } from 'element-ui'

import App from './App.vue'
import utils from '@/utils/pagesUtils'
import router from '@/router/index'
import store from '@/store/index'
import '@/style/pagesIndex.css'

Vue.config.productionTip = false
Vue.prototype['utils'] = utils

Vue.use(Dialog)
Vue.use(Button)
Vue.use(Checkbox)
Vue.prototype.$message = Message

new Vue({
  render: (h) => h(App),
  store,
  router
}).$mount('#app')
