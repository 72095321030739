function getPart(endTime) {
  const current = Math.floor(Date.now() / 1000)
  //时间戳相差多少秒
  let diff = Math.floor(endTime / 1000) - current
  //判断是否过时
  let _diffData
  if (diff > 0) {
    let d = Math.floor(diff / (3600 * 24)) //获取天数
    let h = Math.floor((diff % (3600 * 24)) / (60 * 60)) //获取时
    let m = Math.floor(((diff % (3600 * 24)) % (60 * 60)) / 60) //获取分
    let s = Math.floor(diff % 60) //获取秒
    // d = d < 10? "0" + d : d;
    h = h < 10 ? '0' + h : h
    m = m < 10 ? '0' + m : m
    s = s < 10 ? '0' + s : s
    _diffData = `${d > 0 ? `${d}d : ` : ''}${h}h : ${m}m : ${s}s Left`
  } else {
    _diffData = `00h : 00m : 00s Left`
  }

  return _diffData
}

const gettimers = (time) => {
  // 得到本地时间与GMT时间的时间偏移差
  // const cn = -28800000;
  const t = new Date(time).getTime()
  const offset = new Date().getTimezoneOffset() * 60 * 1000

  // 后台给的时间戳与offset相加得到现在的格林尼治时间
  // const utcTime = t + cn - offset;
  const utcTime = t - offset
  // const dayjs = require("dayjs");
  // 得到正确的时间 如果要转成时间戳就加getTime()方法
  return getPart(utcTime)
}
const isPhone = () => {
  return /Mobi|Android|iPhone/i.test(navigator.userAgent)
}
const documentEvent = (e, domId, fn) => {
  const domBtn = document.querySelector(`#${domId}`)
  const domModal = document.querySelector(`#${domId}_modal`)
  if (![`${domId}_modal`, domId].includes(e.target.id) && !domModal.contains(e.target) && !domBtn.contains(e.target)) {
    fn()
  }
}

const getWalletObj = (key) => {
  let p = window[key]
  if (key === 'bitkeep') {
    p = p?.ethereum
  } else if (key === 'coin98') {
    p = p?.provider
  }
  return p
}
const errorHandler = (error) => {}

const utils = {
  gettimers,
  getWalletObj,
  errorHandler,
  documentEvent,
  isPhone
}
export default utils
