<template>
  <div class="inline-block">
    <div
      class="flex items-center cursor-pointer hover:opacity-80 transition-all active:scale-90"
      :class="{
        'py-2 px-6 bg-[#cafa01] rounded-full': !isText,
        'cursor-not-allowed grayscale': disabled
      }"
    >
      <span class="mr-2" :class="{ 'text-[#cafa01]': isText, 'text-[#000]': !isText }">{{ title }}</span>
      <component v-if="isText" :is="ArrowGreen"></component>
      <component v-else :is="Arrow"></component>
    </div>
  </div>
</template>
<script>
import Arrow from '@/assets/svg/arrow-rt.svg'
import ArrowGreen from '@/assets/svg/arrow-rt-green.svg'

export default {
  name: 'CBtn',
  props: ['isText', 'title', 'disabled'],
  data() {
    return {
      Arrow,
      ArrowGreen
    }
  }
}
</script>
<style scoped lang="stylus">
/**/
</style>
