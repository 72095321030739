<template>
  <div class="InterBtn relative">
    <div
      class="flex justify-between items-center rounded-3xl bg-[#202020] py-1 lg:py-2 px-4 cursor-pointer hover:opacity-75 btn"
      @click.stop="showInterList = !showInterList"
      id="J_inter"
    >
      <component class="w-5" :is="activeInter.logo" alt="" />
      <span v-if="!onlyLogo" class="mx-2">{{ activeInter.chainName }}</span>
      <component v-if="onlyLogo" class="border-l-[1px] border-l-[#2e2e2e] ml-2 pl-2 w-4" :is="Dots" />
      <component
        v-else
        class="rotate-0 transition-transform"
        :class="{ 'rotate-180': showInterList }"
        :is="DownArrow"
      />
    </div>
    <ul id="J_inter_modal" class="inter-list absolute right-0 top-12" :class="{ hidden: !showInterList }">
      <li
        v-for="(item, index) in interMap"
        :key="item.chainId"
        class="flex items-center py-2 px-4 cursor-pointer hover:text-[#cafc01] hover:bg-[#2E2D2D] w-[240px] relative"
        @click="selectInter(index)"
      >
        <!-- <img :src="item.logo" alt="" /> -->
        <component :is="item.logo" class="w-5" />
        <span class="ml-2">{{ item.chainName }}</span>
        <i v-if="index === activeIndex" class="active-dot right-4"></i>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import DownArrow from '@/assets/images/down-arrow.svg'
import Dots from '@/assets/images/dots.svg'
//
export default {
  name: 'InterBtn',
  props: ['onlyLogo'],
  data() {
    return {
      DownArrow,
      Dots,
      activeIndex: 0,
      showInterList: false
    }
  },
  computed: {
    ...mapState('wallet', ['currentChainId']),
    ...mapGetters('wallet', ['walletSignName', 'interMap']),
    currentInterIdx() {
      return this.interMap.findIndex((item) => item.chainId === this.currentChainId)
    },
    activeInter() {
      return this.interMap[this.activeIndex]
    }
  },
  mounted() {
    this.activeIndex = this.currentInterIdx || 0
    document.documentElement.addEventListener('click', this.documentClickEvent)
  },
  beforeDestroy() {
    document.documentElement.removeEventListener('click', this.documentClickEvent)
  },
  methods: {
    ...mapMutations('wallet', ['setChainId']),
    documentClickEvent(e) {
      this.utils.documentEvent(e, 'J_inter', () => {
        this.showInterList = false
      })
    },
    selectInter(idx) {
      this.activeIndex = idx
      this.showInterList = false
      this.setChainId(this.interMap[idx].chainId)
    }
  }
}
</script>
<style scoped lang="stylus">
/**/
.btn
  border 1px solid #2e2e2e
.inter-list
  border 1px solid #2e2e2e
  border-radius 24px
  background #202020
  li + li
    border-top 1px solid #2e2e2e
  li:first-child
    border-radius 24px 24px 0 0
  li:last-child
    border-radius 0 0 24px 24px
.active-dot
  position absolute
  width 8px
  height 8px
  border-radius 50%
  background #cafa01
</style>
