import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/Layout/pagesLayout.vue'
import Home from '@/view/home/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
          title: 'home'
        }
      }
    ]
  }
]
const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
