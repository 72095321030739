<template>
  <div>
    <div>
      <pages-nav></pages-nav>
      <router-view></router-view>
      <pages-foot></pages-foot>
    </div>
  </div>
</template>

<script>
import PagesNav from '@/Layout/PagesNav.vue'
import PagesFoot from '@/Layout/PagesFoot.vue'

export default {
  components: {
    PagesNav,
    PagesFoot
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="stylus" scoped></style>
