<template>
  <div id="nav" class="flex fixed w-full zi1100">
    <!-- pc nav -->
    <nav class="h-[92px] w-full items-center justify-between hidden lg:flex wrapper">
      <div class="flex items-center">
        <component alt="utopa" class="w-[120px] object-contain" :is="Logo" />
      </div>
      <div class="flex">
        <ul class="flex bg-[#1b1b1b] rounded-full mr-2">
          <li class="text-center flex items-center justify-center" v-for="(item, index) in linkList" :key="item.name">
            <div
              @click="openLink(item.link)"
              class="cursor-pointer px-5 transition-opacity hover:opacity-80"
              :class="{ 'w-full border-l-[1px] border-l-[#2e2e2e]': index !== 0 }"
            >
              <component class="lg:w-[34px] w-[24px] h-[18px] lg:h-[28px] m-auto" :is="item.logo" />
              <!-- <div class="mt-[5px] text-[14px] lg:text-[16px]">{{ item.name }}</div> -->
            </div>
          </li>
        </ul>
        <webBtn :isAppBtn="true" class="mx-3" @click.native="onClickNavBtn" />
        <!-- <i v-if="abbAddress" @click="openAccount" class="btn-addr">{{ abbAddress }}</i>
        <webBtn v-else @click.native="onClickConnect" />
        <InterBtn class="ml-2" /> -->
      </div>
    </nav>
    <!-- h5 nav -->
    <nav class="wrapper h-[52px] flex justify-between items-center lg:hidden m-nav">
      <component alt="utopa" :is="Logo" />
      <webBtn :isAppBtn="true" @click.native="onClickNavBtn" />
    </nav>

    <!--mobile-->
    <section id="navShow" class="py-[8px] mt-[52px] md:mt-[70px] w-0 bg-[#202020] h-screen fixed l-0 t-0">
      <div
        v-for="(item, index) in nav"
        :key="item.id"
        class="py-[8px] px-[20px] text-[18px]"
        style="cursor: pointer"
        @click="targetFn(index)"
      >
        <div :class="{ active: active === index }">
          {{ item.title }}
        </div>
      </div>
      <div class="py-[8px] px-[20px] text-[18px] flex items-center" @click="goInvite">
        <component :is="Gift" alt="" />
        <span class="mx-2" :class="{ 'text-[#cafa01]': active === 4 }">Invite</span>
        <component :is="Fire" alt="" />
      </div>
    </section>
    <!-- info dialog -->
    <el-dialog title="Launch App" :visible.sync="infoDialogVisible" :width="dialogWidth" :append-to-body="true">
      <div class="pt-5 px-5">
        <p>
          The website you will enter is a community-deployed and maintained open-source UtopiaFi instance, hosted and
          served on the distributed IPFS network.
        </p>
        <p>
          Please read <span class="cursor-pointer" @click="openCond">UtopiaFi Terms And Conditions</span> before
          entering
        </p>
        <p>By clicking Agree, you accept</p>
        <p><el-checkbox v-model="agreeInfo"></el-checkbox> Do not show this message again for 30 days.</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="agreeInfoFn">Agree</el-button>
      </span>
    </el-dialog>
    <!-- wallets dialog -->
    <!-- <el-dialog
      title="Connect your wallet"
      :visible.sync="walletsDialogVisible"
      :width="dialogWidth"
      :append-to-body="true"
    >
      <div
        class="wallet-item hover:bg-slate-700"
        v-for="(item, index) in walletMap"
        :key="index"
        @click="onWalletClick(item.signName)"
      >
        <span>{{ item.walletName }}</span>
        <img v-if="['Coin98 Wallet', 'Token Pocket'].includes(item.walletName)" class="w-10" :src="item.src" alt="" />
        <component v-else :is="item.src" />
      </div>
    </el-dialog> -->
    <!-- account dialog -->
    <!-- <el-dialog title="Account" :visible.sync="accountDialogVisible" :width="dialogWidth" :append-to-body="true">
      <div class="p-5">
        <section class="flex items-center justify-between h-20">
          <img
            v-if="['Coin98 Wallet', 'Token Pocket'].includes(currentWallet)"
            class="w-10"
            :src="currentWalletLogo"
            alt=""
          />
          <component v-else :is="currentWalletLogo" />
          <span class="cursor-pointer hover:text-[#cafc01]" @click="disconnect">Disconnect</span>
        </section>
        <section class="account-addr">{{ abbAddress }}</section>
        <section class="flex items-center justify-between h-20">
          <span class="bg-[#CAFC01] text-black rounded-3xl px-4 py-1 cursor-pointer" @click="viewInExplorer"
            >View on explorer</span
          >
          <span class="cursor-pointer hover:text-[#cafc01]" @click="copyAddr">Copy Address</span>
        </section>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import { ethers } from 'ethers'
import { mapState, mapGetters, mapMutations } from 'vuex'
import webBtn from '@/components/webBtn/webBtn.vue'
import InterBtn from '@/components/InterBtn/InterBtn.vue'
import { EventBus } from '@/utils/eventBus'
import Frame from '@/assets/images/Frame.svg'
import Logo from '@/assets/images/logo.svg'
import LogoSingle from '@/assets/images/logo-single.svg'
import Gift from '@/assets/images/gift.svg'
import Fire from '@/assets/images/fire.svg'
import TwitterWhite from '@/assets/svg/twitter-white.svg'
import TelegramWhite from '@/assets/svg/telegram-white.svg'
import DiscordWhite from '@/assets/svg/discord-white.svg'

const APP_BASE_URL = process.env.VUE_APP_APP_BASE_URL
export default {
  components: {
    webBtn,
    InterBtn
  },
  data() {
    return {
      Frame,
      Logo,
      LogoSingle,
      Gift,
      Fire,
      active: 0,
      nav: [
        {
          title: 'Home',
          link: 'home',
          id: 0
        },
        {
          title: 'Item',
          link: '',
          id: 1
        },
        {
          title: 'Steps',
          link: '',
          id: 2
        },
        {
          title: 'Q&A',
          link: '',
          id: 3
        }
      ],
      timer: null,
      check: false,
      //
      infoDialogVisible: false,
      agreeInfo: false,
      walletsDialogVisible: false,
      accountDialogVisible: false,
      walletAddr: '',
      //
      dialogWidth: '30%',
      //
      linkList: [
        {
          name: 'Twitter',
          logo: TwitterWhite,
          link: 'https://twitter.com/UtopiaWeb3'
        },
        {
          name: 'Discord',
          logo: DiscordWhite,
          link: 'https://discord.gg/4yqZZRJA6s'
        },
        {
          name: 'Telegram',
          logo: TelegramWhite,
          link: 'https://t.me/utopiafinance'
        }
      ]
    }
  },
  computed: {
    ...mapState('wallet', ['walletAddress', 'currentWallet', 'walletMap', 'currentChainId']),
    ...mapGetters('wallet', [
      'abbAddress',
      'currentWalletLogo',
      'walletSignName',
      'currentInterInfo',
      'currentInterExplorerUrl'
    ]),
    isInvitePage() {
      return this.$route.name === 'invite'
    }
  },
  mounted() {
    // h5 or pc
    const screenWidth = screen.width
    this.dialogWidth = screenWidth < 768 ? '90%' : '30%'
    //
    window.addEventListener('scroll', this.scrollBgEvent)
    // this.aboutNav()

    // this.setDefaultNetwork()
    // this.tryConnectWallet()
    // wallet listener
    // this.walletMap.forEach((item) => {
    //   this.addWalletListener(item.signName)
    // })
    // home connect btn listener
    EventBus.$on('LaunchApp', () => {
      this.onClickNavBtn()
    })
  },

  methods: {
    ...mapMutations('wallet', ['setWalletAddr', 'setWalletName']),
    tryConnectWallet() {
      const sn = this.walletSignName
      if (sn) {
        this.setWalletAddr('')
        this.setWalletName('')
        this.removeListener(sn)
        this.onWalletClick(sn)
      }
    },
    aboutNav() {
      this.ScrollTop(0)
      window.addEventListener('scroll', this.scrollBgEvent)
      document.body.addEventListener('click', this.clickEvent)
      if (this.isInvitePage) {
        this.active = 4
      } else {
        window.addEventListener('scroll', this.scrollEvent)
        window.onmousewheel = document.onmousewheel = () => {
          clearInterval(this.timer)
          this.check = false
        }
      }
    },
    clickEvent(e) {
      if (e.target.id === 'imgClick') return
      document.querySelector('#navShow').style.width = '0'
    },
    scrollBgEvent() {
      if (document.documentElement.scrollTop > 92) {
        document.querySelector('#nav').style.backgroundColor = '#1B1B1B'
      } else {
        document.querySelector('#nav').style.backgroundColor = 'transparent'
      }
    },
    scrollEvent() {
      if (this.check) return
      this.nav.forEach((item, index) => {
        if (
          document.getElementById(item.title).offsetTop < document.documentElement.scrollTop &&
          document.documentElement.scrollTop < this.getH(item.title)
        ) {
          this.active = index
        }
      })
    },
    getH(v) {
      return document.getElementById(v).offsetTop + document.getElementById(v).offsetHeight
    },
    getScro(t) {
      let hf = document.getElementById(t).offsetTop
      hf = hf !== 0 ? hf - 90 : hf
      this.ScrollTop(hf)
    },
    ScrollTop(number = 0) {
      //
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        let nowTop = document.body.scrollTop + document.documentElement.scrollTop
        if (number !== nowTop) {
          let everTop = (number - nowTop) / 10
          everTop = everTop > 0 ? Math.ceil(everTop) : Math.floor(everTop)
          document.body.scrollTop = document.documentElement.scrollTop = nowTop + everTop
        } else {
          clearInterval(this.timer) // 清除计时器
          this.check = false
        }
      }, 16)
    },
    targetFn(index) {
      this.check = true
      this.active = index
      this.$nextTick(() => {
        this.getScro(this.nav[index].title)
      })
      if (this.isInvitePage) {
        this.$router.push({
          name: 'home',
          query: this.$route.query
        })
      }
    },
    navShowFn() {
      const clientWidth = document.body.clientWidth
      const leftNavDom = document.querySelector('#navShow')
      if (clientWidth > 800) {
        leftNavDom.style.width = '300px'
        return
      }
      if (clientWidth > 500) {
        leftNavDom.style.width = '200px'
        return
      }
      leftNavDom.style.width = '30%'
    },
    setDefaultNetwork() {
      const { network } = this.$route.query
      network ||
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            network: this.currentChainId
          }
        })
    },
    openCond() {
      window.open('https://docs.utopiafi.com/utopiafi-terms-and-conditions')
    },
    openAccount() {
      this.accountDialogVisible = true
    },
    agreeInfoFn() {
      if (this.agreeInfo) {
        // 30 day
        const time = new Date().getTime() + 1000 * 60 * 60 * 24 * 30
        // localStorage.setItem('USER_ACCEPT_SIGN',true)
        localStorage.setItem('USER_ACCEPT_SIGN_TIME', time)
      }
      this.infoDialogVisible = false
      this.toTrade()
    },
    openLaunchInfo() {
      this.infoDialogVisible = true
    },
    toTrade() {
      location.href = `${APP_BASE_URL}.utopiafi.com${location.search}`
    },
    onClickNavBtn() {
      // localStorage.getItem('USER_ACCEPT_SIGN')
      const limitTime = localStorage.getItem('USER_ACCEPT_SIGN_TIME')
      const now = new Date().getTime()
      if (limitTime > now) {
        this.toTrade()
      } else {
        this.openLaunchInfo()
      }
    },
    onClickConnect() {
      this.walletsDialogVisible = true
    },
    // connect wallet
    async onWalletClick(signName) {
      let p = this.utils.getWalletObj(signName)
      const provider = new ethers.providers.Web3Provider(p)
      try {
        await provider.send('wallet_switchEthereumChain', [{ chainId: this.currentChainId }])
        this.connectWallet(signName)
      } catch (error) {
        // TODO: create error common
        console.log('🚀 ~ file: PagesNav.vue:308 ~ onWalletClick ~ error:', error)
        switch (error.code) {
          case 4902:
            try {
              await provider.send('wallet_addEthereumChain', [this.currentInterInfo])
              this.connectWallet(signName)
            } catch (error) {
              console.log('🚀 ~ file: PagesNav.vue:319 ~ onWalletClick ~ error:', error)
              switch (error.code) {
                case -32002:
                  this.$message({
                    message: 'Unconfirmed operation in your wallet！',
                    offset: 22,
                    center: true,
                    type: 'warning'
                  })
                  break
                case 4001:
                  this.$message({
                    message: 'Add chain cancelled',
                    offset: 22,
                    center: true,
                    type: 'warning'
                  })
                  break

                default:
                  break
              }
            }
            break
          case -32002:
            this.$message({
              message: 'Unconfirmed operation in your wallet！',
              offset: 22,
              center: true,
              type: 'warning'
            })
            break
          case 4001:
            this.$message({
              message: 'Switch chain cancelled',
              offset: 22,
              center: true,
              type: 'warning'
            })
            break
        }
      }
    },
    async connectWallet(key) {
      let p = this.utils.getWalletObj(key)
      if (!p) return
      const provider = new ethers.providers.Web3Provider(p)
      try {
        const account = await provider.send('eth_requestAccounts')
        this.setWalletAddr(account[0])
        this.setWalletName(this.walletMap.find((item) => item.signName === key).walletName)
        this.walletsDialogVisible = false
        // this.accountDialogVisible = true
      } catch (error) {
        console.log('🚀 ~ file: PagesNav.vue:321 ~ connectWallet ~ error:', error)
        switch (error.code) {
          case -32002:
            this.$message({
              message: 'Unconfirmed operation in your wallet！',
              offset: 22,
              center: true,
              type: 'warning'
            })
            break
          case 4001:
            this.$message({
              message: 'Transaction cancelled',
              offset: 22,
              center: true,
              type: 'warning'
            })
            break
          default:
            break
        }
      }
    },
    addWalletListener(key) {
      const p = this.utils.getWalletObj(key)
      if (!p) return
      p.on('accountsChanged', (account) => {
        this.setWalletAddr(account[0])
        this.setWalletName(this.walletMap.find((item) => item.signName === key).walletName)
      })
      p.on('chainChanged', async (chainId) => {
        console.log('🚀 ~ file: PagesNav.vue:328 ~ p.on ~ chainId:', chainId)
        if (this.walletAddress && this.currentChainId !== chainId) {
          this.disconnect()
        }
      })
      p.on('disconnect', (err) => {
        console.log('🚀 ~ file: PagesNav.vue:430 ~ disconnect ~ err:', err)
      })
    },
    removeListener(key) {
      let p = this.utils.getWalletObj(key)
      if (!p) return
      p.removeAllListeners()
    },
    disconnect() {
      this.setWalletAddr('')
      this.setWalletName('')
      this.accountDialogVisible = false
      this.walletsDialogVisible = true
      this.removeListener(this.walletSignName)
    },
    copyAddr() {
      navigator.clipboard.writeText(this.walletAddress)
    },
    viewInExplorer() {
      window.open(`${this.currentInterExplorerUrl}/address/${this.walletAddress}`)
    },
    goInvite() {
      this.$router.push({
        name: 'invite',
        query: this.$route.query
      })
    }
  },
  watch: {
    currentChainId() {
      if (this.walletAddress) {
        this.disconnect()
      }
    },
    '$route.name'(newVal) {
      if (newVal === 'invite') {
        this.active = 4
        window.removeEventListener('scroll', this.scrollEvent)
        this.$nextTick(() => {
          this.ScrollTop(0)
        })
      } else {
        window.addEventListener('scroll', this.scrollEvent)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
#nav {
  transition all 1s
}

.active {
  color #CAFC01
}

.btn-addr{
  height 40px
  line-height 40px
  color #CAFC01
  padding 0 15px
  border-radius 100px
  border 1px solid #CAFC01
  &:hover{
    color white
    cursor pointer
    background rgba(202, 252, 1, 0.2)
  }
}

.btn-addr-h5{
  height 30px
  line-height 30px
  color #CAFC01
  padding 0 10px
  border-radius 100px
  border 1px solid #CAFC01
  &:hover{
    color white
    cursor pointer
    background rgba(202, 252, 1, 0.2)
  }
}

#navShow {
  overflow: hidden;
  transition all .5s
}

.navPd {
  padding-left 45px
  padding-right 45px
}

.wallet-item {
  display flex
  align-items center
  justify-content space-between
  height 80px
  padding 0 20px
  cursor pointer
  &+.wallet-item{
    border-top 1px solid black
  }

}
.wallet-item:last-child{
  border-radius 0 0 24px 24px
}
.account-addr {
  background-color #333
  border-radius 80px
  padding 20px
}

.zi1100{
  z-index 1100
}


/deep/.el-dialog {
  background-color #202020
  border-radius 24px

  .el-dialog__header{
    border-bottom 1px solid black
  }
  .el-dialog__title{
    font-size 17px
    color white
    font-weight 700
  }
  .el-dialog__close{

    &::before{
      content ''
      display  block
      width 20px
      height 20px
      background: url('@/assets/images/close.png')
      background-size 100% 100%
    }
  }
  .el-dialog__body{
    color white
    font-size 16px
    padding 0
    p{
      margin-bottom 15px
      word-break normal
      font-weight 400
      >span{
        color #CAFC01
        text-decoration underline
      }
    }
  }
  .el-dialog__footer{
    display flex
    align-items center
    justify-content center
    .dialog-footer{
      width 100%
    }
    .el-button{
      width 100%
      padding 20px 0
      background: #CAFC01;
      border-radius: 24px;
      color black
      border 0 none
      letter-spacing: 0.5px;
      font-weight 700
    }
  }
  .el-checkbox__input.is-checked{
    .el-checkbox__inner{
      background-color #CAFC01
      &::after{
        border-color black
      }
    }
  }
}
</style>
