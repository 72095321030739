<template>
  <div class="inline-block">
    <div
      class="flex justify-between items-center bg-[#1B1B1B] border-[1px] border-[#2e2e2e] rounded-3xl px-4 py-3 lg:px-9 lg:py-7"
    >
      <component class="w-[44px] h-[44px] lg:w-[64px] lg:h-[64px]" :is="info.tokenLogo" />
      <div class="flex flex-col ml-2 lg:ml-4">
        <span class="text-[16px] lg:text-[32px]">{{ info.title }}</span>
        <span class="text-[12px] lg:text-[14px] text-[#a8a8a8]">{{ info.name }}</span>
      </div>
      <div class="flex flex-col mx-4 lg:mx-8">
        <span class="text-[16px] lg:text-[32px]">${{ info.price }}</span>
        <span class="text-[12px] lg:text-[14px]" :class="{ 'text-[#FF4A4A]': !isUp, 'text-[#cafa01]': isUp }"
          >${{ info.changePrice }}({{ info.rate }}%)</span
        >
      </div>
      <component class="w-[57px] h-[53px] lg:w-[141px]" :is="formatK(info.title)" />
    </div>
  </div>
</template>
<script>
import KGreenETH from '@/assets/svg/k-green-eth.svg'
import KGreenBTC from '@/assets/svg/k-green-btc.svg'
import KRedBTC from '@/assets/svg/k-red-btc.svg'
import KRedETH from '@/assets/svg/k-red-eth.svg'

export default {
  name: 'CTokenCard',
  props: ['info'],
  data() {
    return {
      KGreenETH,
      KGreenBTC,
      KRedBTC,
      KRedETH
    }
  },
  computed: {
    isUp() {
      return this.info.changePrice > 0
    }
  },
  methods: {
    formatK(title) {
      const map = {
        BTC: this.isUp ? KGreenBTC : KRedBTC,
        ETH: this.isUp ? KGreenETH : KRedETH
      }
      return map[title]
    }
  }
}
</script>
<style scoped lang="stylus">
/**/
</style>
