<template>
  <div class="home">
    <section class="sec1 pt-[120px]">
      <div class="wrapper flex flex-wrap lg:flex-nowrap justify-between">
        <div>
          <p class="text-[40px] lg:text-[64px]">Decentralized Derivative Trading Protocol</p>
          <ul class="flex flex-col lg:flex-row justify-between text-[#a8a8a8] text-[20px] my-6">
            <li>100% Autonomy</li>
            <li>100% Transparency</li>
            <li>100% Profit-Sharing</li>
          </ul>
          <CBtn title="Launch App" @click.native="launchApp" />
        </div>
        <img class="my-10 w-full lg:my-0 lg:w-[600px]" :src="require('@/assets/images/home-star.png')" alt="" />
      </div>
    </section>
    <section class="sec2 mt-10">
      <div class="wrapper">
        <ul class="flex flex-wrap lg:flex-nowrap items-center justify-center">
          <li v-for="(item, idx) in companyLogo" :key="item.id" class="my-2 mx-2 lg:mx-4">
            <component
              class="lg:w-[150px] hover:scale-110 transition-transform"
              :class="{ 'w-[100px]': [0, 1].includes(idx), 'w-[80px]': [2, 3, 4].includes(idx) }"
              :is="item.logo"
            />
          </li>
        </ul>
      </div>
    </section>
    <section class="sec3 py-[60px] lg:py-[120px]">
      <div class="wrapper">
        <ul class="flex flex-wrap lg:flex-nowrap justify-around">
          <li v-for="item in tokenList" :key="item.title" class="my-2 hover:scale-105 transition-transform">
            <CTokenCard :info="item" />
          </li>
        </ul>
      </div>
    </section>
    <section class="sec4">
      <div class="wrapper">
        <h3 class="text-[24px] lg:text-[36px]">Advantages Of UtopiaFi</h3>
        <CBtn class="my-10" :is-text="true" title="Click For Details" @click.native="toDetail('sec4')" />
        <ul class="hidden lg:grid lg:grid-cols-3 grid-cols-1 gap-3">
          <li
            v-for="item in cardList"
            :key="item.title"
            class="p-4 border-[1px] border-[#2e2e2e] rounded-3xl hover:scale-105 transition-transform"
          >
            <component :is="item.img" />
            <h4 class="text-[20px] my-2">{{ item.title }}</h4>
            <p class="text-[14px] lg:text-[16px] text-[#a8a8a8]">{{ item.text }}</p>
          </li>
        </ul>
        <div class="lg:hidden">
          <CSwiper :list="cardList" />
        </div>
      </div>
    </section>
    <section class="sec5 mt-[60px] py-[60px] lg:mt-[120px] lg:py-[120px]">
      <div class="wrapper flex flex-wrap lg:flex-nowrap items-center justify-between">
        <div>
          <p class="text-[24px] lg:text-[36px]">UtopiaFi is being deployed on two networks: zkSync Era & Arbitrum</p>
          <p class="mt-4 text-[14px] lg:text-[20px] text-[#a8a8a8]">
            You can choose the network and experience smooth trade
          </p>
        </div>
        <ul class="w-full lg:w-[90%]">
          <li v-for="item in netList" :key="item.title" class="net-card my-4">
            <div class="flex items-center">
              <component class="lg:w-[50px] lg:h-[50px]" :is="item.logo" />
              <span class="text-[24px] lg:text-[32px] ml-2">{{ item.title }}</span>
            </div>
            <CBtn :title="netCardBtnTitle" @click.native="launchApp" />
          </li>
        </ul>
      </div>
    </section>
    <section class="sec6 mt-[60px] lg:mt-[120px]">
      <div class="wrapper">
        <h3 class="text-[24px] lg:text-[36px]">Two Tokens of the UtopiaFi Ecosystem</h3>
        <p class="text-[14px] lg:text-[20px] text-[#a8a8a8] my-10">Core support throughout UtopiaFi</p>
        <CBtn :is-text="true" title="Click For Details" @click.native="toDetail('sec6')" />
        <ul class="mt-10 grid lg:grid-cols-2 grid-cols-1 gap-3">
          <li v-for="item in utoTokenList" :key="item.title" class="border-[1px] border-[#2e2e2e] rounded-3xl p-4">
            <div class="flex items-center">
              <component width="50px" height="50px" :is="item.logo" />
              <div class="ml-2">
                <p class="text-[32px]">{{ item.title }}</p>
                <p class="text-[14px] text-[#a8a8a8]">{{ item.subTitle }}</p>
              </div>
            </div>
            <p class="text-[16px] my-4">{{ item.intro }}</p>
            <p class="text-[16px] text-[#a8a8a8]">{{ item.subIntro }}</p>
            <CBtn
              class="mt-8"
              :title="item.btnTitle"
              @click.native="toAppPage(item.pageName, item.btnDisable)"
              :disabled="item.btnDisable"
            />
          </li>
        </ul>
      </div>
    </section>
    <section class="sec7 mt-[60px] py-[60px] lg:mt-[120px] lg:py-[120px]">
      <div class="wrapper flex flex-wrap lg:flex-nowrap items-center justify-between">
        <div class="w-full lg:w-[560px]">
          <h3 class="text-[24px] lg:text-[36px]">Own a UtopiaFi NFT to become a founding member of UtopiaFi</h3>
          <p class="text-[14px] lg:text-[20px] text-[#a8a8a8] my-10">Why hold UtopiaFi NFT? The six rights tell you</p>
          <CBtn :is-text="true" title="Mint Now" @click.native="toAppPage('nft')" />
        </div>
        <img :src="require('@/assets/images/nfts.png')" class="my-4 w-full lg:w-[480px]" />
      </div>
    </section>
    <section class="sec8 py-[60px] lg:py-[120px] hidden">
      <div class="wrapper flex flex-wrap lg:flex-nowrap items-center justify-center lg:justify-between">
        <div class="w-full lg:w-[560px]">
          <h3 class="text-[24px] lg:text-[36px]">Security & Audits</h3>
          <p class="text-[14px] lg:text-[20px] text-[#a8a8a8] my-10">
            Our smart contracts have been unit tested and have undergone multiple independent audits from CertiK.
          </p>
          <CBtn :is-text="true" title="Check Details" @click.native="toDetail('sec8')" />
        </div>
        <img :src="require('@/assets/images/certik.png')" class="mt-8 w-[180px] lg:w-[230px] lg:mr-[200px]" />
      </div>
    </section>
  </div>
</template>
<script>
import { EventBus } from '@/utils/eventBus'
import CBtn from './components/CBtn.vue'
import CSwiper from './components/CSwiper.vue'
import CTokenCard from './components/CTokenCard.vue'
import 'swiper/swiper-bundle.css'

import OpenSea from '@/assets/svg/opensea.svg'
import Radiant from '@/assets/svg/radiant.svg'
import Zksync from '@/assets/svg/zksync.svg'
import Chainlink from '@/assets/svg/chainlink.svg'
import Arbitrum from '@/assets/svg/arbitrum.svg'
import ETH from '@/assets/svg/eth.svg'
import BTC from '@/assets/svg/btc.svg'
import UPS from '@/assets/svg/ups.svg'
import UPT from '@/assets/svg/upt.svg'
import ci1 from '@/assets/svg/card-img1.svg'
import ci2 from '@/assets/svg/card-img2.svg'
import ci3 from '@/assets/svg/card-img3.svg'
import ci4 from '@/assets/svg/card-img4.svg'
import ci5 from '@/assets/svg/card-img5.svg'
import ci6 from '@/assets/svg/card-img6.svg'
import ZksyncLogo from '@/assets/svg/zksync-logo.svg'
import ArbLogo from '@/assets/svg/arb-logo.svg'

export default {
  name: 'HomePage',
  components: {
    CBtn,
    CTokenCard,
    CSwiper
  },
  data() {
    return {
      companyLogo: [
        { id: 1, logo: OpenSea },
        { id: 2, logo: Arbitrum },
        { id: 3, logo: Chainlink },
        { id: 4, logo: Zksync },
        { id: 5, logo: Radiant }
      ],
      tokenList: [
        {
          title: 'BTC',
          name: 'Bitcoin',
          symbol: 'BTCUSDT',
          tokenLogo: BTC,
          price: 0,
          changePrice: 0,
          rate: 0
        },
        {
          title: 'ETH',
          name: 'Ethereum',
          symbol: 'ETHUSDT',
          tokenLogo: ETH,
          price: 0,
          changePrice: 0,
          rate: 0
        }
      ],
      cardList: [
        {
          img: ci1,
          title: 'Dual Rewards',
          text: 'Users can use deposit certificates from other platforms to cast UPS tokens, and enjoy dual rewards.'
        },
        {
          img: ci2,
          title: 'High utilization rate of LP funds',
          text: 'PvP-AMM model enables LP to achieve higher utilization rate compared to other platforms, resulting in higher expected returns.'
        },
        {
          img: ci3,
          title: 'High security of LP funds',
          text: 'The efficient market equilibrium achieved through PvP-AMM model can keep the risk exposure of LP consistently low.'
        },
        {
          img: ci4,
          title: 'Low trading costs for traders',
          text: 'Traders enjoy low fees, especially when trading with UPS. Opening, rollover, and liquidation fees are all zero.'
        },
        {
          img: ci5,
          title: 'Strong scalability',
          text: 'The adoption of a coin-based settlement model allows for underlying asset and collateral without any connection.'
        },
        {
          img: ci6,
          title: 'Exclusive trading sub-pool',
          text: 'The trading sub-pool allows KOLs to transparently and efficiently convert their community members.'
        }
      ],
      netList: [
        {
          title: 'zkSync',
          logo: ZksyncLogo,
          link: ''
        },
        {
          title: 'Arbitrum',
          logo: ArbLogo,
          link: ''
        }
      ],
      utoTokenList: [
        {
          title: 'UPS',
          logo: UPS,
          subTitle: 'UtopiaFi Share',
          intro:
            'UPS is the proof of UPP (UtopiaFi Fund Pool) share income, which can be used as collateral for derivative trade',
          subIntro: "Trader's losses will be injected into UPP, and UPS prices will continue to rise",
          btnTitle: 'Cast UPS',
          btnDisable: false,
          pageName: 'casting'
        },
        {
          title: 'UPT',
          logo: UPT,
          subTitle: 'UtopiaFi Token',
          intro:
            'UPT is the governance token of UtopiaFi, and users who own UPT can participate in voting and governance',
          subIntro: 'The fee will be used to continuously repurchase UPT',
          btnTitle: 'Buy UPT',
          btnDisable: true,
          pageName: 'farm'
        }
      ],
      timer: null,
      netCardBtnTitle: 'Launch App'
    }
  },
  mounted() {
    this.setPolling()
    screen.width < 768 ? (this.netCardBtnTitle = 'App') : ''
  },
  methods: {
    launchApp() {
      EventBus.$emit('LaunchApp')
    },
    toDetail(sec) {
      const map = {
        sec4: 'https://docs.utopiafi.com/tokenomic',
        sec6: 'https://docs.utopiafi.com/tokenomic',
        sec8: ''
      }
      window.open(map[sec])
    },
    toAppPage(pageName, disable = false) {
      if (disable) return
      const APP_BASE_URL = process.env.VUE_APP_APP_BASE_URL
      location.href = `${APP_BASE_URL}.utopiafi.com/${pageName}${location.search}`
    },
    setPolling() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.tokenList.forEach((item, idx) => {
          this.getTokenInfo(item.symbol, idx)
        })
      }, 2000)
    },
    getTokenInfo(symbol, idx) {
      const BASE_URL = process.env.VUE_APP_BASE_URL || location.origin
      const api = '/api/market/24h'
      const params = `?symbols=[%22${symbol}%22]`
      fetch(BASE_URL + api + params)
        .then((res) => res.json())
        .then((data) => {
          const pInfo = data.data[0]
          for (const key in pInfo) {
            if (Object.hasOwnProperty.call(pInfo, key)) {
              const newNum = parseFloat(pInfo[key]).toFixed(2)
              pInfo[key] = newNum
            }
          }
          this.tokenList[idx].rate = pInfo.priceChangePercent
          this.tokenList[idx].price = pInfo.lastPrice
          this.tokenList[idx].changePrice = pInfo.priceChange //
        })
        .catch((error) => console.log(error))
    },
    toThousands(num) {
      const res = []
      let splits = num.toString().split('.')
      splits[0]
        .split('')
        .reverse()
        .map(function (item, i) {
          if (i % 3 == 0 && i != 0) {
            res.push(',')
          }
          res.push(item)
        })
      return res.reverse().join('') + (splits.length > 1 ? '.' + splits[1] : '')
      // return (num || 0).toString().replace(/(?<!\.\d*)\B((?=(\d{3})+(?!\d)))/, ',')
    }
  }
}
</script>
<style scoped lang="stylus">
/**/
.sec1
  background-image url('@/assets/images/bg-top-stars.png')
  background-size contain
.sec3
  background-image url('@/assets/images/bg-top-slide.png')
  background-size 100% 100%
  background-repeat no-repeat
.sec5
  background #1b1b1b
  .net-card
    background-image url('@/assets/images/bg-little.png')
    background-size contain
    padding 32px 16px
    display flex
    align-items center
    justify-content space-between
    border 1px solid #2e2e2e
    border-radius 24px
    &+.net-card
      margin-top 16px
.sec7
  background #1b1b1b
</style>
