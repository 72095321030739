<template>
  <div class="flex justify-end">
    <button
      :class="[
        isH5 ? 'h5ViewMore' : 'viewMore',
        'flex justify-center items-center userSele',
        isH5 && !isAppBtn ? 'h5-connect' : ''
      ]"
    >
      <component v-if="!isAppBtn" class="mr-[8px] w-[13px] h-[12px] imgClick" :is="Wallet" />
      <div class="imgClick hidden lg:flex">
        {{ isAppBtn ? 'Launch App' : 'Connect' }}
      </div>
      <div class="imgClick flex lg:hidden">
        {{ isAppBtn ? 'App' : 'Connect' }}
      </div>
    </button>
  </div>
</template>

<script>
import Wallet from '@/assets/images/wallet.svg'
export default {
  name: 'MoreButton',
  props: {
    isAppBtn: {
      default: false
    }
  },
  data() {
    return {
      isH5: false,
      Wallet
    }
  },
  mounted() {
    this.isH5 = document.body.clientWidth <= 900
  }
}
</script>

<style scoped>
.imgClick {
  cursor: pointer;
}
</style>

<style lang="stylus" scoped>
.viewMore {
  padding: 15px;
  height: 40px;
  font-size: 14px;
  color: #000;
  background: rgba(202, 252, 1, 1);
  border-radius: 32px;
  transition: all .3s;
  &:focus {
    background-color #8BC244;
  }
}

.h5ViewMore {
  padding: 5px;
  height: 30px;
  width: 60px;
  text-align: center;
  font-size: 14px;
  color: #000;
  background: rgba(202, 252, 1, 1);
  border-radius: 32px;
}

.h5-connect{
  width 100px
}

.viewMore:hover {
  background: rgba(202, 252, 1, 0.8);
}

.viewMore:active {
  transform: scale(0.98);
  background: rgba(202, 252, 1, 1);
}
</style>
