import Metamask from '@/assets/images/metamask.svg'
import Okx from '@/assets/images/okx.svg'
import BitKeep from '@/assets/images/BitKeep.svg'
import Arb from '@/assets/images/arb.svg'
import zkSync from '@/assets/images/zksync.svg'

const getNetwork = () => {
  const query = new URLSearchParams(location.search)
  const chainIdsStr = process.env.VUE_APP_CHAINID_ARR || ''
  const chainIds = chainIdsStr.split(',')
  const ci = query.get('network')
  const chainId = chainIds.includes(ci) ? ci : '0x144'
  setNetworkQuery(chainId)
  return chainId
}
const setNetworkQuery = (str) => {
  let { origin, pathname } = location
  const query = new URLSearchParams(location.search)
  let extra = '?'
  query.forEach((val, key) => {
    if (key === 'network') {
      val = str
    }
    extra += `${key}=${val}&`
  })
  if (extra.endsWith('&')) extra = extra.slice(0, -1)
  history.pushState({}, 0, origin + pathname + extra)
}
// initial state
const state = () => ({
  walletAddress: localStorage.getItem('wa'),
  currentWallet: localStorage.getItem('wn'),
  currentChainId: getNetwork(),
  allInterMap: [
    {
      chainName: 'Arbitrum One',
      chainId: '0xa4b1',
      nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18
      },
      rpcUrls: ['https://arb1.arbitrum.io/rpc'],
      blockExplorerUrls: ['https://arbiscan.io/'],
      logo: Arb,
      contract: {
        po: process.env.VUE_APP_ARBITRUM_ONE_PRICE_ORACLE_ADDR,
        us: process.env.VUE_APP_ARBITRUM_ONE_UTOPIA_SLOTH_ADDR
      },
      token: {
        ETH_ADDR: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
        USDT_ADDR: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
        USDC_ADDR: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
        ARB_ADDR: '0x912CE59144191C1204E64559FE8253a0e49E6548',
        BTC_ADDR: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f'
      }
    },
    {
      chainName: 'Arbitrum Goerli',
      chainId: '0x66eed',
      nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18
      },
      rpcUrls: ['https://endpoints.omniatech.io/v1/arbitrum/goerli/public'],
      blockExplorerUrls: ['https://goerli.arbiscan.io'],
      logo: Arb,
      contract: {
        po: process.env.VUE_APP_ARBITRUM_GOERLI_PRICE_ORACLE_ADDR,
        us: process.env.VUE_APP_ARBITRUM_GOERLI_UTOPIA_SLOTH_ADDR
      },
      token: {
        ETH_ADDR: '0xEe01c0CD76354C383B8c7B4e65EA88D00B06f36f',
        USDT_ADDR: '0x189895363DAeebDCe90BBc8fd9C7C215055430E3'
      }
    },
    {
      chainName: 'zkSync Era Mainnet',
      chainId: '0x144',
      nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18
      },
      rpcUrls: ['https://zksync2-mainnet.zksync.io'],
      blockExplorerUrls: ['https://explorer.zksync.io'],
      logo: zkSync,
      contract: {
        po: process.env.VUE_APP_ZKSYNC_MAIN_PRICE_ORACLE_ADDR,
        us: process.env.VUE_APP_ZKSYNC_MAIN_UTOPIA_SLOTH_ADDR
      },
      token: {
        ETH_ADDR: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91'
      }
    },
    {
      chainName: 'zkSync Era Testnet',
      chainId: '0x118',
      nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18
      },
      rpcUrls: ['https://testnet.era.zksync.dev'],
      blockExplorerUrls: ['https://zksync2-testnet.zkscan.io'],
      logo: zkSync,
      contract: {
        po: process.env.VUE_APP_ZKSYNC_TEST_PRICE_ORACLE_ADDR,
        us: process.env.VUE_APP_ZKSYNC_TEST_UTOPIA_SLOTH_ADDR
      },
      token: {
        ETH_ADDR: '0x20b28B1e4665FFf290650586ad76E977EAb90c5D'
      }
    }
  ],
  walletMap: [
    {
      signName: 'ethereum',
      walletName: 'Metamask',
      src: Metamask
    },
    {
      signName: 'okxwallet',
      walletName: 'Okx Wallet',
      src: Okx
    },
    {
      signName: 'bitkeep',
      walletName: 'BitKeep Wallet',
      src: BitKeep
    },
    {
      signName: 'coin98',
      walletName: 'Coin98 Wallet',
      src: require('@/assets/images/coin98.png')
    },
    {
      signName: 'ethereum',
      walletName: 'Token Pocket',
      src: require('@/assets/images/tp.jpg')
    }
  ]
})

// getters
const getters = {
  abbAddress: (state) => {
    const addr = state.walletAddress
    if (!addr) {
      return ''
    }
    return addr.slice(0, 6) + '...' + addr.slice(-4)
  },
  walletSignName: (state) => {
    const name = state.currentWallet
    return state.walletMap.find((item) => item.walletName === name)?.signName || ''
  },
  currentWalletLogo: (state) => {
    const name = state.currentWallet
    return state.walletMap.find((item) => item.walletName === name)?.src || ''
  },
  interMap(state) {
    const chainIdsStr = process.env.VUE_APP_CHAINID_ARR || ''
    const chainIds = chainIdsStr.split(',')
    const list = []
    chainIds.forEach((item) => {
      const i = state.allInterMap.find((obj) => item === obj.chainId)
      i && list.push(i)
    })
    return list
  },
  currentInterName: (state) => {
    const currentChainId = state.currentChainId
    return state.allInterMap.find((item) => item.chainId === currentChainId)?.chainName || ''
  },
  currentInterInfo: (state) => {
    const currentChainId = state.currentChainId
    const { chainName, chainId, nativeCurrency, rpcUrls, blockExplorerUrls } = state.allInterMap.find(
      (item) => item.chainId === currentChainId
    )
    return {
      chainName,
      chainId,
      nativeCurrency,
      rpcUrls,
      blockExplorerUrls
    }
  },
  currentInterContract: (state) => {
    const chainId = state.currentChainId
    return state.allInterMap.find((item) => item.chainId === chainId)?.contract || {}
  },
  currentInterRpcUrl: (state) => {
    const chainId = state.currentChainId
    return state.allInterMap.find((item) => item.chainId === chainId)?.rpcUrls[0] || ''
  },
  currentInterExplorerUrl: (state) => {
    const chainId = state.currentChainId
    return state.allInterMap.find((item) => item.chainId === chainId)?.blockExplorerUrls[0] || ''
  },
  currentInterTokenAddr: (state) => {
    const chainId = state.currentChainId
    return state.allInterMap.find((item) => item.chainId === chainId)?.token || {}
  }
}

// actions
const actions = {}

// mutations
const mutations = {
  setWalletAddr(state, addr) {
    state.walletAddress = addr
    localStorage.setItem('wa', addr || '')
  },
  setWalletName(state, name) {
    state.currentWallet = name
    localStorage.setItem('wn', name || '')
  },
  setChainId(state, chainId) {
    state.currentChainId = chainId
    setNetworkQuery(chainId)
    // localStorage.setItem('ci', chainId || '')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
