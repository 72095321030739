<template>
  <div class="relative w-full overflow-hidden">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div v-for="i in 3" :key="i + 10" class="swiper-slide w-[300px] h-[430px] flex flex-col">
          <li
            v-for="j in 2"
            :key="swiperIdx(i, j)"
            class="my-2 p-4 border-[1px] border-[#2e2e2e] rounded-3xl text-start"
          >
            <component class="w-[30px] h-[30px]" :is="list[swiperIdx(i, j)].img" />
            <h4 class="text-[20px] my-2">{{ list[swiperIdx(i, j)].title }}</h4>
            <p class="text-[14px] lg:text-[16px] text-[#a8a8a8]">{{ list[swiperIdx(i, j)].text }}</p>
          </li>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
//
export default {
  name: 'CSwiper',
  props: ['list'],
  mounted() {
    new Swiper('.swiper-container', {
      slidesPerView: 1,
      spaceBetween: 30,
      centeredSlides: true,
      autoplay: {
        delay: 5000,
        stopOnLastSlide: false,
        disableOnInteraction: true
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev'
      // }
    })
  },
  methods: {
    swiperIdx(i, j) {
      return j - 1 + (i - 1) * 2
    }
  }
}
</script>
<style scoped lang="stylus">
/**/
:deep(.swiper-pagination-bullet) {
  background: linear-gradient(0deg, #555555, #555555),
      linear-gradient(180deg, rgba(183, 183, 183, 0.91) 0%, #403E3C 100%);
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(183, 183, 183, 0.91) 0%, #403E3C 100%);
}
:deep(.swiper-pagination-bullet-active)
  background #CAFC02

.swiper-container {
  width: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  list-style: none;

  /* Center slide text vertically */
  transition: 300ms;
  transform: scale(0.8);
}

.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
}
</style>
