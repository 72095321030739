<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="stylus">
#app {
  .wrapper {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    //padding: 0 30px;

    @media screen and (min-width: 640px) {
      width: 100%;
      padding-left: 50px;
      padding-right: 50px;
      //padding: 0 50px;
    }
    @media screen and (min-width: 769px) {
      width: 100%;
      padding-left: 70px;
      padding-right: 70px;
      //padding: 0 70px;
    }
    @media screen and (min-width: 1024px) {
      width: 100%;
      //padding: 0 100px;
      padding-left: 100px;
      padding-right: 100px;
    }
    @media screen and (min-width: 1280px) {
      width: 1200px;
      margin: auto;
      //padding 0
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.el-message {
  background #202020!important
  border 1px solid #2E2E2E!important
  border-radius 24px!important
  min-width 100px!important
  padding 12px 20px!important
  .el-message__content{
    font-weight: 700!important
    font-size: 16px!important
    color: #FFFFFF!important
    opacity: 0.5!important
  }
  .el-message__icon{
    display none!important
  }
}
.el-message--success {
  background #373F1A!important
  border 1px solid rgba(202, 252, 1, 0.1)!important
  .el-message__content{
    color #CAFC01!important
    opacity: 1!important
  }
}
.el-message--warning {
  background #694714!important
  border 1px solid rgba(252, 152, 1, 0.3)!important
  .el-message__content{
    color #FC9801!important
    opacity: 1!important
  }
}
.el-message--error {
  background #692D2D!important
  border 1px solid rgba(255, 74, 74, 0.3)!important
  .el-message__content{
    color #FF4A4A!important
    opacity: 1!important
  }
}
@media screen and (max-width: 700px){
  .el-message {
    width 100%!important
  }
}
</style>
