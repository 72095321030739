<template>
  <div class="bg-[#1B1B1B] py-[60px]">
    <div class="flex flex-col lg:flex-row justify-between items-center wrapper">
      <section class="flex flex-col lg:flex-row items-center">
        <component :is="Logo" />
        <p class="lg:ml-10 my-4 text-[#a8a8a8] text-[16px]">© 2023 UtopiaFi. All Rights Reserved.</p>
      </section>
      <section class="flex justify-between items-center">
        <div
          v-for="(item, index) in logos"
          :key="item.name"
          @click="openLink(item)"
          class="cursor-pointer px-5 transition-opacity hover:opacity-80"
          :class="{ 'w-full border-l-[1px] border-l-[#2e2e2e]': index !== 0 }"
        >
          <component width="32px" height="32px" :is="item.src" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import TwitterWhite from '@/assets/svg/twitter-white.svg'
import TelegramWhite from '@/assets/svg/telegram-white.svg'
import DiscordWhite from '@/assets/svg/discord-white.svg'
import OpenSeaWhite from '@/assets/svg/opensea-white.svg'
// import
import Logo from '@/assets/images/logo.svg'
export default {
  data() {
    return {
      Logo,
      logos: [
        {
          name: 'Twitter',
          url: 'https://twitter.com/UtopiaWeb3',
          src: TwitterWhite
        },
        {
          name: 'Telegram',
          url: 'https://t.me/utopiafinance',
          src: TelegramWhite
        },
        {
          name: 'Discord',
          url: 'https://discord.gg/4yqZZRJA6s',
          src: DiscordWhite
        },
        {
          name: 'OpenSea',
          src: OpenSeaWhite,
          url: 'https://opensea.io/collection/utopiafi-com-nft'
        }
      ]
    }
  },

  mounted() {},

  methods: {
    openLink(logo) {
      window.open(logo.url)
    }
  }
}
</script>

<style lang="stylus" scoped></style>
